import { reactive, toRefs } from 'vue';
import { useFacilities } from '@/composables/use-facilities';
import { useSdk } from '@/composables/use-mycure';
import { omit } from 'lodash';
const MEDICINE_CONFIGURATIONS_SERVICE_NAME = 'medicine-configurations';
const MEDICINE_FORMULATIONS_SERVICE_NAME = 'medicine-formulations';
const MEDICINES_SERVICE_NAME = 'medicines';

function mapMedicineConfigurations (items) {
  return items.map(item => {
    const medicine = item?.$populated?.medicine || {};
    return {
      ...omit(item, ['item.$populated']),
      medicine,
    };
  });
}

const medicineConfigurationsState = reactive({
  medicineConfigurations: [],
});

export function useMedicineConfigurations () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();

  async function listItems (opts) {
    // TODO: Find a way to cache the data
    if (!!medicineConfigurationsState.medicineConfigurations?.length  && !opts?.forceFetch) return medicineConfigurationsState.medicineConfigurations;

    const activeFacility = await getActiveFacility();
    const organizationId = opts?.organization || activeFacility?.id;
    const query = {
      organization: organizationId,
      $sort: { createdAt: -1 },
      $populate: {
        medicine: {
          service: 'medicines',
          localKey: 'medicine',
        }
      }
    }
    const { data } = await sdk.list(MEDICINE_CONFIGURATIONS_SERVICE_NAME, query);
    medicineConfigurationsState.medicineConfigurations = mapMedicineConfigurations(data);
    return mapMedicineConfigurations(data);
  }

  async function createItem (data) {
    const activeFacility = await getActiveFacility();
    const organizationId = data.organization || activeFacility?.id;

    const payload = {
      ...omit(data, ['organization', '$unset']),
      organization: organizationId,
    };

    return sdk.create(MEDICINE_CONFIGURATIONS_SERVICE_NAME, payload);
  }

  async function updateItem (id, data) {
    const payload = {
      ...omit(data, ['organization', 'id', 'medicine']),
    };  
    return sdk.update(MEDICINE_CONFIGURATIONS_SERVICE_NAME, id, payload);
  }

  async function getItem (id) {
    // Fetch the item from the server
    return sdk.get(MEDICINE_CONFIGURATIONS_SERVICE_NAME, id);
  }

  async function removeItem (id) {
    console.warn('id', id);
    return sdk.delete(MEDICINE_CONFIGURATIONS_SERVICE_NAME, id);
  }

  return {
    ...toRefs(medicineConfigurationsState),
    listItems,
    createItem,
    getItem,
    updateItem,
    removeItem,
  };
}

const medicineState = reactive({
  medicines: [],
});

function mapMedicines (items) {
  return items.map(item => {
    const formulations = item?.$populated?.formulations || [];
    return {
      ...item,
      formulations: formulations.map(({ formulation }) => (formulation)),
    };
  });
}

export function useMedicines () {
  const sdk = useSdk();

  async function listItems (opts) {

    const query = {};

    if (opts.tags) {
      query.tags = {
        $in: opts.tags,
      };
    };

    if (opts?.searchString) {
      query.$or = [
        { genericName: { $regex: opts.searchString, $options: 'i' } },
      ];
    }
    
    if (opts?.genericName) {
      query.genericName = opts.genericName;
    }
    
    const { data } = await sdk.list(MEDICINES_SERVICE_NAME, query);

    medicineState.medicines = mapMedicines(data);
    
    return mapMedicines(data);
  }

  return {
    ...toRefs(medicineState),
    listItems,
  };
};

export function useMedicineFormulations () {
  const sdk = useSdk();

  async function listItems (opts) {
    const query = { 
      ...opts,
    };
    const { data } = await sdk.list(MEDICINE_FORMULATIONS_SERVICE_NAME, query);
    return data.map(formulation => (`${formulation.form} ${formulation.strength}`));
  }

  return {
    listItems,
  };
}