export const RECORD_TYPE = 'medication-order';

export const FORMULATION_CLASSIFICATIONS = [
  {
    value: 'Oral',
    text: 'Oral',
  },
  {
    value: 'Injectable',
    text: 'Injectable',
  },
  {
    value: 'Skin',
    text: 'Skin',
  },
  {
    value: 'Inhalation',
    text: 'Inhalation',
  },
  {
    value: 'Dermal',
    text: 'Dermal',
  },
  {
    value: 'Suppositories',
    text: 'Suppositories',
  },
  {
    value: 'Others',
    text: 'Others',
  },
];

export const MEDICINE_LIST = [
  {
    id: 'antibotic-eye-drops',
    genericName: 'Antibiotic Eye Drops',
    createdAt: 1584374872741,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-alendronate',
    genericName: 'Alendronate',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-allopurinol',
    genericName: 'Allopurinol',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-alprazolam',
    genericName: 'Alprazolam',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-amikacin',
    genericName: 'Amikacin',
    createdAt: 1584374872716,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-aspirin',
    genericName: 'Aspirin',
    createdAt: 1584374872713,
    hasLinkedVariants: true,
  },
  {
    id: 'medicine-atracurium',
    genericName: 'Atracurium',
    createdAt: 1584374872714,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-atropine',
    genericName: 'Atropine',
    createdAt: 1584374872710,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-azathioprine',
    genericName: 'Azathioprine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-baclofen',
    genericName: 'Baclofen',
    createdAt: 1584374872714,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-betahistine',
    genericName: 'Betahistine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-biperiden',
    genericName: 'Biperiden',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-bromazepam',
    genericName: 'Bromazepam',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-butorphanol',
    genericName: 'Butorphanol',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-calcium carbonate',
    genericName: 'Calcium Carbonate',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-calcium carbonate + cholecalciferol (vit. d3)',
    genericName: 'Calcium Carbonate + Cholecalciferol (Vit. D3)',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-carbamazepine',
    genericName: 'Carbamazepine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-cefalexin',
    genericName: 'Cefalexin',
    createdAt: 1584374872716,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-celecoxib',
    genericName: 'Celecoxib',
    createdAt: 1584374872714,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-chloral hydrate',
    genericName: 'Chloral Hydrate',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-chlorpromazine',
    genericName: 'Chlorpromazine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-cinnarizine',
    genericName: 'Cinnarizine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-clonazepam',
    genericName: 'Clonazepam',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-clozapine',
    genericName: 'Clozapine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-codeine',
    genericName: 'Codeine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-colchicine',
    genericName: 'Colchicine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-conjugated equine',
    genericName: 'Conjugated Equine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-cyclophosphamide',
    genericName: 'Cyclophosphamide',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-d alendronate +',
    genericName: 'D Alendronate +',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-d escitalopram',
    genericName: 'D Escitalopram',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-d hydroxychloroquine',
    genericName: 'D Hydroxychloroquine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-d meropenem',
    genericName: 'D Meropenem',
    createdAt: 1584374872716,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-d methylphenidate',
    genericName: 'D Methylphenidate',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-d oxycodone',
    genericName: 'D Oxycodone',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-d zolmitriptan',
    genericName: 'D Zolmitriptan',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-dantrolene',
    genericName: 'Dantrolene',
    createdAt: 1584374872714,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-dexamethasone',
    genericName: 'Dexamethasone',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-diazepam',
    genericName: 'Diazepam',
    createdAt: 1584374872710,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-diclofenac',
    genericName: 'Diclofenac',
    createdAt: 1584374872709,
    hasLinkedVariants: true,
  },
  {
    id: 'medicine-diphenhydramine',
    genericName: 'Diphenhydramine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-edrophonium',
    genericName: 'Edrophonium',
    createdAt: 1584374872710,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-ephedrine',
    genericName: 'Ephedrine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-ergotamine',
    genericName: 'Ergotamine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-ertapenem',
    genericName: 'Ertapenem',
    createdAt: 1584374872716,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-esmolol',
    genericName: 'Esmolol',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-fentanyl',
    genericName: 'Fentanyl',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-flunarizine',
    genericName: 'Flunarizine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-fluoxetine',
    genericName: 'Fluoxetine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-flupentixol',
    genericName: 'Flupentixol',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-fluphenazine',
    genericName: 'Fluphenazine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-flurazepam',
    genericName: 'Flurazepam',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-furosemide',
    genericName: 'Furosemide',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-gabapentin',
    genericName: 'Gabapentin',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-galantamine',
    genericName: 'Galantamine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-gentamicin',
    genericName: 'Gentamicin',
    createdAt: 1584374872716,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-glycerol (glycerin)',
    genericName: 'Glycerol (Glycerin)',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-haloperidol',
    genericName: 'Haloperidol',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-ibuprofen',
    genericName: 'Ibuprofen',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-imipramine',
    genericName: 'Imipramine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-indometacin',
    genericName: 'Indometacin',
    createdAt: 1584374872713,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-ketoprofen',
    genericName: 'Ketoprofen',
    createdAt: 1584374872710,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-ketorolac',
    genericName: 'Ketorolac',
    createdAt: 1584374872710,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-levodopa + carbidopa',
    genericName: 'Levodopa + Carbidopa',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-lithium carbonate',
    genericName: 'Lithium Carbonate',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-lorazepam',
    genericName: 'Lorazepam',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-magnesium sulfate',
    genericName: 'Magnesium Sulfate',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-mannitol',
    genericName: 'Mannitol',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-meclozine (meclizine)',
    genericName: 'Meclozine (Meclizine)',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-medroxy-progesterone',
    genericName: 'Medroxy-Progesterone',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-mefenamic acid',
    genericName: 'Mefenamic Acid',
    createdAt: 1584374872713,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-methotrexate',
    genericName: 'Methotrexate',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-midazolam',
    genericName: 'Midazolam',
    createdAt: 1584374872710,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-morphine',
    genericName: 'Morphine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-nalbuphine',
    genericName: 'Nalbuphine',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-naproxen',
    genericName: 'Naproxen',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-neostigmine',
    genericName: 'Neostigmine',
    createdAt: 1584374872710,
    hasLinkedVariants: true,
  },
  {
    id: 'medicine-netilmicin',
    genericName: 'Netilmicin',
    createdAt: 1584374872716,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-olanzapine',
    genericName: 'Olanzapine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-pancuronium',
    genericName: 'Pancuronium',
    createdAt: 1584374872715,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-paracetamol',
    genericName: 'Paracetamol',
    createdAt: 1584374872711,
    hasLinkedVariants: true,
  },
  {
    id: 'medicine-pethidine (meperidine)',
    genericName: 'Pethidine (Meperidine)',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-phenobarbital',
    genericName: 'Phenobarbital',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-phenytoin',
    genericName: 'Phenytoin',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-piribedil',
    genericName: 'Piribedil',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-propranolol',
    genericName: 'Propranolol',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-pyridostigmine',
    genericName: 'Pyridostigmine',
    createdAt: 1584374872710,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-quetiapine',
    genericName: 'Quetiapine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-raloxifene',
    genericName: 'Raloxifene',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-risperidone',
    genericName: 'Risperidone',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-rivastigmine',
    genericName: 'Rivastigmine',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-rocuronium',
    genericName: 'Rocuronium',
    createdAt: 1584374872715,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-sertraline',
    genericName: 'Sertraline',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-spectinomycin',
    genericName: 'Spectinomycin',
    createdAt: 1584374872716,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-suxamethonium (succinylcholine)',
    genericName: 'Suxamethonium (Succinylcholine)',
    createdAt: 1584374872714,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-thiopental sodium',
    genericName: 'Thiopental Sodium',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-topiramate',
    genericName: 'Topiramate',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-tramadol',
    genericName: 'Tramadol',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-valproate disodium/ valproic acid',
    genericName: 'Valproate Disodium/ Valproic Acid',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-vecuronium',
    genericName: 'Vecuronium',
    createdAt: 1584374872715,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-yerba buena [mentha cordifolia opiz (fam. labiatae)]',
    genericName: 'Yerba Buena [Mentha Cordifolia Opiz (Fam. Labiatae)]',
    createdAt: 1584374872711,
    hasLinkedVariants: false,
  },
  {
    id: 'medicine-zolpidem',
    genericName: 'Zolpidem',
    createdAt: 1584374872712,
    hasLinkedVariants: false,
  },
];

export const FREQUENCY_OPTS = [
  {
    value: 'once a day',
    text: 'Once a day',
  },
  {
    value: '2x a day',
    text: '2x a day',
  },
  {
    value: '3x a day',
    text: '3x a day',
  },
  {
    value: '4x a day',
    text: '4x a day',
  },
  {
    value: 'as needed',
    text: 'As needed',
  },
  {
    value: 'as directed',
    text: 'As directed',
  },
  {
    value: 'immediately',
    text: 'Immediately',
  },
  {
    value: 'before breakfast',
    text: 'Before breakfast',
  },
  {
    value: 'before meals',
    text: 'Before meals',
  },
  {
    value: 'after meals',
    text: 'After meals',
  },
  {
    value: 'in the morning',
    text: 'In the morning',
  },
  {
    value: 'in the afternoon',
    text: 'In the afternoon',
  },
  {
    value: 'at bedtime',
    text: 'At bedtime',
  },
  {
    value: 'once a week',
    text: 'Once a week',
  },
  {
    value: '2x a week',
    text: '2x a week',
  },
  {
    value: '3x a week',
    text: '3x a week',
  },
  {
    value: 'every hour',
    text: 'Every hour',
  },
  {
    value: 'every 2 hours',
    text: 'Every 2 hours',
  },
  {
    value: 'every 3 hours',
    text: 'Every 3 hours',
  },
  {
    value: 'every 4 hours',
    text: 'Every 4 hours',
  },
  {
    value: 'every 6 hours',
    text: 'Every 6 hours',
  },
  {
    value: 'every 8 hours',
    text: 'Every 8 hours',
  },
  {
    value: 'every 12 hours',
    text: 'Every 12 hours',
  },
  {
    value: 'every month',
    text: 'Every month',
  },
];

export const FORM_FIELDS = [
  {
    field: 'genericName',
    label: 'Generic Name',
    type: 'select',
    placeholder: 'e.g Paracetamol',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    field: 'brandName',
    label: 'Brand Name',
    type: 'text',
    placeholder: 'e.g. Biogesic',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    field: 'formulationClassification',
    label: 'Route of Administration',
    type: 'select',
    placeholder: 'e.g. Oral',
    model: '',
    update: true,
    options: FORMULATION_CLASSIFICATIONS,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    field: 'formulation',
    label: 'Formulation',
    type: 'text',
    placeholder: 'e.g. 500mg tablet',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    field: 'dispense',
    type: 'text',
    label: 'Dispense',
    placeholder: 'e.g. 1 box',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    field: 'dosageSig',
    label: 'Dosage',
    type: 'text',
    placeholder: 'e.g. 1 tablet',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    field: 'frequency',
    label: 'Frequency',
    type: 'select',
    placeholder: 'e.g. 3x a day',
    model: '',
    update: true,
    options: FREQUENCY_OPTS,
    rules: {
      type: 'string',
      required: false,
    },
  },
  // {
  //   field: 'startedAt',
  //   label: 'Start date',
  //   type: 'date',
  //   placeholder: 'e.g. 2010-05-04',
  //   model: '',
  //   update: true,
  //   rules: {
  //     type: 'date',
  //     required: false,
  //   },
  // },
  // {
  //   field: 'endedAt',
  //   label: 'End date',
  //   type: 'date',
  //   placeholder: 'e.g. 2010-09-24',
  //   model: '',
  //   update: true,
  //   rules: {
  //     type: 'date',
  //     required: false,
  //   },
  // },
  // {
  //   field: 'reasonForPrescription',
  //   label: 'Indication',
  //   type: 'text',
  //   placeholder: 'e.g. Hypertension',
  //   model: '',
  //   update: true,
  //   rules: {
  //     type: 'string',
  //     required: false,
  //   },
  // },
  {
    field: 'frequencyReminderInterval',
    label: 'Refills',
    type: 'number',
    placeholder: 'No. of refills, e.g. 5',
    model: '',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  // {
  //   field: 'drugGrouping',
  //   label: 'NDC code',
  //   type: 'text',
  //   placeholder: 'e.g. 00030-23291-39',
  //   model: '',
  //   update: true,
  //   rules: {
  //     type: 'string',
  //     required: false,
  //   },
  // },
  // {
  //   field: 'provider',
  //   label: 'Prescriber',
  //   placeholder: 'e.g. Dr. Fox',
  //   model: '',
  //   update: true,
  //   rules: {
  //     type: 'string',
  //     required: false,
  //   },
  // },
  {
    field: 'note',
    label: 'Notes',
    type: 'textarea',
    placeholder: 'e.g Take with food',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
];

export const UPDATE_FIELDS = [FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field)];

export const FORM_MODEL = [FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.model;
  return acc;
}, {})];

export const PRESCRIPTION = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.model;
  return acc;
}, {});

export const FORM_RULES = [FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {})];
